<template>
  <div id="oauthSubmit" @keydown.enter="handleSubmit">
    <a-row justify="center" align="top">
      <a-col span="6">
        <a-card :bordered="true" class="oauth-submit-card">
          <div v-if="!loading">
            <div class="oauth-submit-img">
              <img src="~@/assets/images/login-logo-urtrade.png">
            </div>
            <p>尊敬的{{ name }}</p>
            <p style="font-size: 20px;font-weight: bold">是否允许此应用访问你的信息？</p>
            <div>
              <p>授权方需要得到你的许可才能执行以下操作：</p>
              <p>1. 访问你的账户信息</p>
              <p>2. 获得你的交易操作权限</p>
              <span style="font-size: 10px;line-height: 15px;margin-top: 10px">接受这些权限即表明你允许此应用按照服务条款和隐私声明中的相关规定使用你的数据。</span>
            </div>
            <div class="flex-align" style="justify-content: end; margin-top: 36px">
              <a-button @click="handleCancel">取消</a-button>
              <a-button type="primary" style="margin-left: 18px" @click="handleSubmit">授权</a-button>
            </div>
          </div>
          <div v-else class="load-area flex-align">
            <a-spin size="large" />
          </div>
        </a-card>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { _login } from '@/api'
import { message } from 'ant-design-vue'
export default {
  name: 'OauthSubmit',
  setup () {
    const router = useRouter()
    const route = useRoute()
    const state = reactive({
      loading: true,
      name: '',
      userName: '',
      merchantNo: '',
      redirectUrl: '',
      token: ''
    })

    const handleSubmit = async () => {
      const data = {
        token: state.token,
        userName: state.userName,
        merchantNo: state.merchantNo,
        redirectUrl: state.redirectUrl
      }
      const res = await _login.confirmAuth(data)
      if (res.data.succ) {
        window.location.href = res.data.data
      } else {
        message.error(res.data.resMsg)
      }
    }

    const handleCancel = () => {
      window.location.href = state.redirectUrl
    }

    const init = async () => {
      state.loading = true
      state.userName = route.query.userName
      state.token = route.query.token
      state.merchantNo = route.query.merchantNo
      state.redirectUrl = route.query.redirectUrl

      const res = await _login.checkLoginStatus({
        userName: state.userName,
        token: state.token
      })
      if (res.data.succ) {
        state.name = res.data.data
        state.loading = false
      } else {
        message.error(res.data.resMsg)
        router.push({
          path: '/oauth/oauthLogin',
          query: {
            merchantNo: state.merchantNo,
            redirectUrl: state.redirectUrl
          }
        })
      }
    }

    init()

    return {
      handleSubmit,
      handleCancel,
      ...toRefs(state)
    }
  }
}
</script>
<style lang="less" scoped>
  #oauthSubmit {
    height: 100%;
    background-color: white;
    text-align: left;
    .oauth-submit-card {
      height: 450px;
      margin-top: 120px;
      .oauth-submit-img {
        margin-bottom: 24px;
        img {
          width: 180px;
        }
      }
      .load-area {
        height: 402px;
        justify-content: center;
      }
    }
  }
</style>
